<template>
	<main class="main">
		<section class="container" v-if="getPage">
      <Crumbs :data="crumbs"></Crumbs>
			<div class="main__box main__card-page">
				<h1 class="main__title">{{ getPage.title }}</h1>
				<div class="main__text-box main__text-box--full">
					<div class="editor">
						<figure class="image right">
							<!--<img src="@/assets/images/pictures/post-image-bankcard.png" alt="Банковская карта"/>-->
							<img
								src="@/assets/images/pictures/post-image-bankcard-new.png"
								:alt="getPage.title"
							/>
						</figure>
						<p>&nbsp;</p>
						<div v-html="getPage.block1.content2"></div>
					</div>
				</div>
			</div>
		</section>
		<section class="container">
			<div class="main__box">
				<Map
					:data="{
						icon: require('@/assets/images/icons/map_type-3.svg'),
						groupedMarkers: getGroupedPoints,
						title: 'Пункты приема заявлений и выдачи ЕСК',
					}"
					:filters="filters"
				></Map>
			</div>
		</section>
	</main>
</template>

<script>
import Map from '@/components/Map.vue'
import Axios from 'axios'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'CardBank',

	components: { Map, Crumbs },
	props: {
		alias: {
			type: String,
			require: true,
		},
	},
	data() {
		return {
			getPage: null,
		}
	},
	methods: {},
	computed: {
		getGroupedPoints() {
			return this.$store.getters.POINTS_ORGANIZATION_GROUPED?.filter(group => group?.organization?.isBankCardIssuer)
		},
		filters() {
			return {
				cardType: 'isBankCardIssuer',
				list: false,
				type: 'points',
				store: {
					dispatch: 'SET_POINT_LIST_APPLIED_FILTERS',
					getters: 'POINT_LIST_APPLIED_FILTERS',
				},
				filters: [
					{
						name: 'Фильтр',
						data: [
							{
								type: 'checkbox',
								cardType: true,
								title: 'Тип карты',
								list: [
									{
										id: 'isBankCardIssuer',
										name: 'Банковская',
									},
									{
										id: 'isNonFinancialCardIssuer',
										name: 'Нефинансовая',
									},
								],
							},
							{
								type: 'checkbox',
								title: 'Пункт выдачи ЕСК',
								list: this.$store.getters.POINT_LIST_FILTERS,
							},
						],
					},
				],
			}
		},
	},
	created: function() {
		this.filters.cardType = 'isBankCardIssuer'
		this.$root.$on('hideFilterBox', ()=>{
			//document.querySelector('input[value="isBankCardIssuer"]').click();
		})
	},
	mounted() {
        let self = this
		Axios({
			url: `${process.env.VUE_APP_API_VERSION}/page/card-bank`,
			method: 'GET',
		})
		.then(response => {
			//self.$store.dispatch('GET_PAGE', 'card-bank')
			self.getPage = response.data
		})
		.catch(err => {
			console.log(err)
		})
		self.$nextTick(() => {
			this.$store.dispatch('GET_POINTS_ORGANIZATION')
			//setTimeout(function(){
			//	document.querySelector('input[value="isBankCardIssuer"]').click();
			//}, 4000)
		})
		this.crumbs = [
			{
				link: 'HolderHome',
				text: 'Главная',
			},
			{
				link: null,
				text: 'Банковская карта',
			},
		]
	},
}
</script>
